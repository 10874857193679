.dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
}

.dashboard_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0 2vw;
  color: white;
  height: inherit;
  width: 14vw;
}

.dashboard_btn {
  padding: 4px;
  font-size: 1rem;
  text-decoration: underline;
  border: none;
  color: white;
  background: none;
}
