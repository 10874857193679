#home {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

#homepage_display {
    width: 85vw;
    height: 75vh;
    margin: 5vh 7.5vw;
    display: flex;
    justify-content: space-between;
}

.homepage_block {
    background: #ffffff27;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    transition-duration: 300ms;
}
.homepage_block:hover {
    background: #ffffff30;
}


#teams_panel {
    height: 75vh;
    width: 30vw;
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

#charts_container {
    height: 75vh;
    width: 52.5vw;
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

#summary_panel {
    height: 7.5vh;
    width: 47.5vw;
    padding: 2.5vh 2.5vw;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

#charts_panel {
    height: 55vh;
    width: 100%;
    padding: 2.5vh 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

#liked_panel {
    height: 37.5vh;
    width: 25vw;
    padding: 2.5vh 2.5vw;
    overflow: hidden;
    overflow-y: scroll;
}

#hated_panel {
    height: 25vh;
    width: 25vw;
    padding: 2.5vh 2.5vw;
    overflow: hidden;
    overflow-y: scroll;
}

.teams_panel_header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.dash_team_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.chart_box {
    display: flex;
    height: 50vh;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.main_chart_container {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-direction: row;
}

.chart_description {
    display: flex;
    width: 80%;
    text-align: justify;
    color: #D5D7E0;
}

.summary_description {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
}

.pie_chart {
    width: 100%;
    height: 320px;
}