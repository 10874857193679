#myTeams {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

#addteams_box {
    display: flex;
    width: 90vw;
    padding: 5vh 5vw;
    justify-content: flex-start;
    align-items: center;
}

.team_list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    width: 31vw;
    padding: 5vh 5vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    background: #ffffff27;
    transition-duration: 300ms;
}
.team_list:hover {
    background: #ffffff30;
}

.team_list h1 {
    margin: 4vh 2vw;
    width: auto;
}

.teams_box {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
}

.my_teams {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0 1vw;
    width: 28vw;
    height: 10vh;
    margin: 1vh 0;
    background: #ffffff27;
}

.my_team_name {
    width: 10vw;
    text-align: start;
}

.my_team_rating {
    width: 10vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.my_team_info {
    height: inherit;
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
}

.my_team_event {
    height: inherit;
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    flex-direction: column;
}

.odds_chart {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 3vh;
    transition-duration: 800ms;
}

.odds_meter p {
    display: none;
    font-size: 1rem;
    transition-duration: 500ms;
    user-select: none;
}

.odds_meter:hover p {
    display: flex;
}


.odds_meter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.75vh;
    transition-duration: 500ms;
    border: 1px solid rgba(255, 255, 255, 0.651);
}

.odds_meter:hover {
    border: none;

}

.teams_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1vw;
    width: 28vw;
}

.percentage_key {
    display: flex
}

.color_box {
    width: 8px;
    height: 8px;
    border: 1% solid white;
    margin: 0 .5vw;
}

.color_key {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 768px) {
    .teams_box {
        flex-direction: column;
    }

    .team_list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 80vw;
        margin-left: 5vw;
        margin-right: 5vw;
        margin-bottom: 2.5vh;
        padding: 1vh 5vw;
    }

    .my_teams {
        width: 80vw;
        height: 10vh;
    }

    .percentage_key {
        display: flex;
        flex-direction: column;
    }

    .color_key {
        justify-content: flex-start;
    }

    .color_box {
        margin: 0 1vw;
    }

    .teams_header {
        width: 100%;
        height: 10vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .my_team_rating_box {
        display: flex;
    }

    .my_team_rating_text {
        display: none;
    }

    .my_team_name {
        width: 38vw;
    }
}