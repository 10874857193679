.login_panel {
  height: 100vh;
  width: 30vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.template_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  background-color: #00000080;
  padding: 3vh 2vw;
}

#login_container {
  height: 34vh;
}

#register_container {
  height: 36vh;
}

#reset_container {
  height: 18vh;
}

.login_btn {
  width: 100%;
}

.login_prompt {
  height: 2vh;
}

.login_link {
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .login_panel {
    width: 100vw;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .template_container {
    padding: 6vw 6vh;
  }

  #login_container {
    height: 54vh;
  }
  
  #register_container {
    height: 60vh;
  }
  
  #reset_container {
    height: 32vh;
  }
}