@font-face {
    font-family: 'Roboto';
    src: url('./content/fonts/Roboto-Regular.ttf');
}

* {
    font-family: 'Roboto';
    color: white;
}

.page {
    /* background: linear-gradient(0deg, #2D3440 0%, #1f242c 100%), linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgb(255, 255, 255) 100%); */
    background: #2D3440;
}

.page_content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 10vh;
    min-height: 90vh;
}

.styled_button {
    background: none;
    border: 2px solid white;
    color: white;
    outline: none;
    padding: 5px;
    text-decoration: none;
    font-size: 1rem;
}
.styled_button:hover {
    background: white;
    color: #2D3440;
}

.dividing_line {
    height: 1px;
    width: 80%;
    /* background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255,255,255,0) 100%); */
    background: rgba(255, 255, 255, 0.685);
}