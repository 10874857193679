#mobile_homepage_display {
    width: 90vw;
    margin: 5vh 5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.mobile_homepage_block {
    background: #ffffff27;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    transition-duration: 300ms;
    margin-bottom: 2.5vh;
}
.mobile_homepage_block:hover {
    background: #ffffff30;
}


#mobile_teams_panel {
    width: 90vw;
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

#mobile_charts_container {
    width: 90vw;
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

#mobile_summary_panel {
    width: 85vw;
    padding: 2.5vh 2.5vw;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

#mobile_charts_panel {
    width: 85vw;
    padding: 2.5vh 2.5vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

#mobile_liked_panel {
    width: 85vw;
    padding: 2.5vh 2.5vw;
}

#mobile_hated_panel {
    width: 85vw;
    padding: 2.5vh 2.5vw;
}

.mobile_teams_panel_header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.mobile_dash_team_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.mobile_chart_box {
    display: flex;
    width: inherit;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.mobile_main_chart_container {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    align-items: center;
    flex-direction: column-reverse;
}

.mobile_chart_description {
    display: flex;
    width: 100%;
    text-align: justify;
    color: #D5D7E0;
    margin-bottom: 2.5vh;
}

.mobile_summary_description {
    display: flex;
    justify-content: flex-start;
    width: 95%;
    padding-left: 5%;
    text-align: left;
}

.mobile_pie_chart {
    height: 70vw;
    width: 90vw;
}