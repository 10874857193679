.browse_teams_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100vw;
}

#all_teams_list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 20vw;
    padding: 5vh 5vw;
    margin: 5vh 7.5vw;
    background: #ffffff27;
    transition-duration: 300ms;
}
#all_teams_list:hover {
    background: #ffffff30;
}


.listbutton {
    size: 2rem;
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    padding: .5rem;
}

.league .listbutton {
    margin-left: 2vw;
}

.teams .listbutton {
    margin-left: 4vw;
}

.button_image {
    height: 1rem;
    filter: invert(1);
    margin-right: 5px;
}

.teams {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.team_names {
    margin-left: 4vw;
    background: none;
    border: none;
    color: white;
    font-size: 1rem;
    padding: .5rem;
}

.add_team {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 60vw;
}

.team_form {
    position: fixed;
    left: 60vw;
    top: 30vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: 40vh;
    width: 20vw;
    padding: 3vh 2vw;
    background: #ffffff27;
    transition-duration: 300ms;
}
.team_form:hover {
    background: #ffffff30;
}


#liked_team_form .button_box #submit_team_button {
    background: #5EDA8D;
}

#hated_team_form .button_box #submit_team_button {
    background: #FF8042;
}

.clickedlistbutton {
    text-decoration: underline;
    margin-left: 4vw;
    background: none;
    border: none;
    color: white;
    font-size: 1rem;
    padding: .5rem;
}

.rating_box {
    display: flex;
    width: 14vw;
    justify-content: space-between;
}

.button_box {
    display: flex;
    width: 10vw;
    justify-content: space-between;
}

.team_name_box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.button_text {
    font-size: 1rem;
}

.form_info {
    text-align: center;
    width: 80%;
}

#info_dividing_line {
    width: 50%;
}

#team_info_form {
    height: 30vh;
    width: 20vw;
    padding: 8vh 2vw;
}

