.header {
    position: fixed;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
    height: 10vh;
    color: white;
    background: #00000080;
    backdrop-filter: blur(4px);
    z-index: 10;
}

.header_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 30vw;
    height: 4vh;
}

#page_title {
    font-size: 2rem;
    height: inherit;
    display: flex;
    align-items: center;
}

.header_panel {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: inherit;
}

#account_panel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 30vw;
}

#nav_panel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40vw;
}

.nav_button {
    text-align: center;
    font-size: 1rem;
    width: auto;
    height: 2.5vh;
    padding: 0 2vw;
    background: none;
    border: none;
    color: white;
    text-decoration: none;
}

#account_button {
    width: 4vw;
}