@font-face {
    font-family: 'RobotoMono';
    src: url('../../../content/fonts/RobotoMono-VariableFont_wght.ttf');
}

#landing {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

#information {
    width: 70vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000080;
}

#title_container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    color: white;
    font-family: 'RobotoMono';
}

@media screen and (max-width: 768px) {
    #landing {
      flex-direction: column;
    }

    #information {
        width: 100vw;
        height: 20vh;
        max-height: 20vh;
    }

    #title_container {
        align-items: center;
    }
  }